<template>
  <section class="tw-text-base">
    <ul
      class="tw-ml-3 md:tw-ml-6 tw-pt-5 md:tw-pt-8 tw-w-auto tw-border-l-2 tw-border-dotted tw-border-gray-e9"
    >
      <li
        v-for="slot in timeSlots"
        :key="slot.id"
        class="tw-mb-5 md:tw-mb-8 tw-w-auto tw-flex tw-flex-row"
      >
        <div
          class="tw-h-3 tw-w-3 tw--ml-1.5 tw-mr-3 md:tw-mr-6 tw-my-auto tw-bg-gray-e9 tw-rounded-full"
        />
        <button
          type="button"
          class="tw-w-full tw-shadow-card tw-rounded-lg tw-flex tw-flex-row"
          @click="showSlotModal(slot)"
        >
          <div
            :class="[
              'tw-flex-shrink-0 tw-h-6 tw-w-6 md:tw-h-10 md:tw-w-10 tw-mx-4 md:tw-mx-8 tw-my-auto tw-flex tw-items-center tw-justify-center tw-rounded-full',
              slotFormat(slot, 'bgColor')
            ]"
          >
            <i
              :class="[
                'fa tw-text-white tw-text-xs md:tw-text-base',
                slotFormat(slot, 'icon')
              ]"
            />
          </div>
          <div class="tw-my-6 tw-mr-4 md:tw-mr-8 tw-flex-grow tw-text-left">
            <span class="tw-text-base tw-font-bold">
              {{ formatHHmm(slot.datetime_start) }} -
              {{ formatHHmm(slot.datetime_end) }}
            </span>
            <br />
            <span :class="slotFormat(slot, 'subHeadingStyle')">
              {{ slotFormat(slot, 'subHeading') }}
            </span>
          </div>
          <div
            class="tw-px-4 md:tw-px-8 tw-h-full tw-flex tw-items-center tw-justify-center tw-border-l tw-border-gray-e9"
          >
            <i class="fa fa-arrow-right tw-text-xs md:tw-text-sm" />
          </div>
        </button>
      </li>
    </ul>
    <BaseModal ref="slotModal" title="Reservatie kijkmoment" @hide="modalClosed">
      <div v-if="selectedTimeSlot !== null" class="tw-text-center">
        <div class="tw-mb-6">
          <p class="tw-text-sm lg:tw-text-base tw-font-semibold">
            {{ formatDateForLocale(selectedTimeSlot.datetime_start) }}
            <br />
            {{ formatHHmm(selectedTimeSlot.datetime_start) }} -
            {{ formatHHmm(selectedTimeSlot.datetime_end) }}
          </p>
        </div>
        <div v-if="selectedTimeSlot.booking_set.length" class="tw-text-left">
          <details
            v-for="(booking, index) in selectedTimeSlot.booking_set"
            :key="booking.id"
            :open="index === 0 ? true : false"
          >
            <summary class="tw-text-base tw-font-semibold link">
              Boeking {{ index + 1 }} ({{
                booking.candidate.first_name +
                  ' ' +
                  booking.candidate.last_name
              }})
            </summary>
            <div
              class="tw-px-5 tw-py-2 tw-text-sm lg:tw-text-base tw-flex tw-flex-col tw-space-y-2"
            >
              <span>
                <i class="fa fa-user tw-mr-2.5" />
                {{ booking.candidate.first_name }}
                {{ booking.candidate.last_name }}
              </span>
              <span>
                <i class="fa fa-language tw-mr-2.5" />
                Voorkeurstaal: {{ USER_LANG_OPTIONS[booking.candidate.language] }}
              </span>
              <a
                :href="'tel:' + booking.candidate.phone_number_mobile"
                class="link tw-underline"
              >
                <i class="fa fa-phone tw-mr-2.5" />
                {{ booking.candidate.phone_number_mobile }}
              </a>
              <a
                :href="'mailto:' + booking.candidate.email"
                class="link tw-underline"
              >
                <i class="fa fa-envelope tw-mr-2.5" />

                {{ booking.candidate.email }}
              </a>
              <a :href="getICS(booking.ics_url)" class="link tw-underline">
                <i class="fa fa-link tw-mr-2.5" />
                Voeg toe aan je eigen agenda
              </a>
              <transition name="fade" mode="out-in">
                <PropertyVisitEditBooking
                  v-if="bookingsToEdit[booking.id]"
                  v-bind="{ slotId: selectedTimeSlot.id, booking }"
                  @update="updateSlotsBookings($event, index)"
                  @cancel="cancelEditBooking(booking.id)"
                />
                <div v-else class="tw-flex tw-gap-4">
                  <FormulateInput
                    type="button"
                    :input-class="['tw-h-8 tw-px-4 md:tw-text-sm']"
                    @click="editBooking(booking)"
                  >
                    <i class="fal fa-pencil tw-mr-1" /> Bewerk
                  </FormulateInput>
                  <FormulateInput
                    type="button"
                    :input-class="['tw-h-8 tw-px-4 md:tw-text-sm tw-bg-error']"
                    @click="removeBooking(booking, index)"
                  >
                    <i class="fal fa-trash tw-mr-1" /> Verwijderen
                  </FormulateInput>
                </div>
              </transition>
            </div>
          </details>
        </div>
        <div
          v-else
          class="tw-text-sm lg:tw-text-base tw-flex tw-flex-col"
        >
          <span class="tw-mb-4">
            <i class="fa fa-user tw-mr-2.5" />
            Nog niet gereserveerd
          </span>
          <FormulateForm
            #default="{ isLoading }"
            name="propertyVisitSlotDelete"
            @submit="removeTimeSlot(selectedTimeSlot)"
          >
            <button
              type="submit"
              class="tw-text-red-600 hover:tw-text-opacity-60 tw-underline"
              :disabled="isLoading"
            >
              <i
                :class="[
                  'fas tw-mr-2.5',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-trash'
                ]"
              />
              Tijdslot verwijderen
            </button>
            <FormulateErrors />
          </FormulateForm>
        </div>
      </div>
    </BaseModal>
  </section>
</template>

<script>
import { deletePropertyTimeSlot, deletePropertVisitBooking } from '@/services/calendarService'
import { formatHHmm, formatDateForLocale, USER_LANG_OPTIONS } from '@/utils/helpers'
import { startLoadingModal, successModal, questionModal, errorModal } from '@/modalMessages'

export default {
  name: 'PropertyVisitSlotsList',
  components: {
    PropertyVisitEditBooking: () =>
      import(/* webpackChunkName: "PropertyVisitEditBooking" */ '@/components/properties/PropertyVisitEditBooking')
  },
  props: {
    timeSlots: {
      type: Array,
      required: true
    }
  },
  constants: { USER_LANG_OPTIONS },
  data () {
    return {
      propertyId: this.$route.params.id,
      selectedTimeSlot: null,
      bookingsToEdit: {},
      bookingUpdated: false
    }
  },
  methods: {
    formatHHmm,
    formatDateForLocale,
    showSlotModal (slot) {
      this.selectedTimeSlot = slot
      this.$refs.slotModal.show()
    },
    hideSlotModal () {
      this.$refs.slotModal.hide()
    },
    modalClosed () {
      if (this.bookingUpdated) this.$emit('updateTimeSlots') // If booking was updated, fetch slots again
      this.selectedTimeSlot = null
    },
    slotFormat (slot, key) {
      const notReserved = {
        bgColor: 'tw-bg-success',
        icon: 'fa-clock-o',
        subHeadingStyle: 'tw-text-sm tw-text-gray-cc',
        subHeading: 'Nog niet gereserveerd'
      }
      const reserved = {
        bgColor: 'tw-bg-primary',
        icon: 'fa-lock',
        subHeadingStyle: 'tw-text-sm tw-font-bold',
        subHeading: 'Kijkmoment gereserveerd'
      }

      return slot.booking_set.length ? reserved[key] : notReserved[key]
    },
    async removeTimeSlot (slot) {
      try {
        const answer = await questionModal('Wil je dit tijdslot verwijderen? Dit kan niet ongedaan gemaakt worden.')
        if (!answer.value) return

        startLoadingModal('Tijdslot verwijderen...')
        const response = await deletePropertyTimeSlot(this.propertyId, slot.id)
        const message = `${formatDateForLocale(slot.datetime_start)} (${formatHHmm(slot.datetime_start)} - ${formatHHmm(slot.datetime_end)}) is verwijderd.`
        successModal(message)

        this.hideSlotModal()
        this.$emit('updateTimeSlots')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyVisitSlotDelete')
      }
    },
    async removeBooking (booking, index) {
      try {
        const answer = await questionModal('Wil je deze boeking verwijderen? Dit kan niet ongedaan gemaakt worden.')
        if (!answer.value) return

        startLoadingModal('Boeking verwijderen...')
        const response = await deletePropertVisitBooking(this.propertyId, this.selectedTimeSlot.id, booking.id)
        successModal('Boeking verwijderd.')

        this.$delete(this.selectedTimeSlot.booking_set, index)
        this.bookingUpdated = true
        return response
      } catch (error) {
        errorModal('Fout bij het verwijderen van de boeking, probeer het opnieuw.')
      }
    },
    getICS (ics_url) {
      return process.env.VUE_APP_CALENDAR_API_URL + ics_url
    },
    editBooking (booking) {
      this.$set(this.bookingsToEdit, booking.id, booking)
    },
    cancelEditBooking (bookingId) {
      this.$delete(this.bookingsToEdit, bookingId)
    },
    updateSlotsBookings (booking, index) {
      this.$set(this.selectedTimeSlot.booking_set, index, booking)
      this.$delete(this.bookingsToEdit, booking.id)
      this.bookingUpdated = true
    }
  }
}
</script>
